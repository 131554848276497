import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_CONFIG_OV

export default class IpsService {
  listarIps (name, params) {
    return http.get(`${baseUrl}/ips/search/${name}`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  paginate (params) {
    return http.get(`${baseUrl}/ips/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  getIps (id) {
    return http.get(`${baseUrl}/ips/find/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  crearIps (data) {
    return http.post(`${baseUrl}/ips`, data, {
      headers: {
        loading: true
      }
    })
  }

  editarIps (data) {
    return http.put(`${baseUrl}/ips/${data.Id}`, data, {
      headers: {
        loading: true
      }
    })
  }

  eliminarIps (id) {
    return http.delete(`${baseUrl}/ips/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
